import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import EditSchool from 'src/components/edit-school/edit-school';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const EditSchoolPage = () => {
  return (
    <Router basepath="/schools/:schoolId">
      <EditSchoolRoute path="/edit" />
    </Router>
  );
};

interface EditSchoolRouteProps extends RouteComponentProps {
  schoolId?: string;
}

const EditSchoolRoute: React.FC<EditSchoolRouteProps> = ({ schoolId }) => {
  return (
    <Layout>
      <SEO title="Edit School" />
      <PrivateRoute>
        <EditSchool schoolId={schoolId} />
      </PrivateRoute>
    </Layout>
  );
};

export default EditSchoolPage;
